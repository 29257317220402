
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import  {
  refreshSelectMedia,
  getConfigs,
  renderImageUrl,
  showMultipleMedia,
  showSingleMedia,
  setConfigs,
} from "@/core/services/AppService";
import { default_image } from "@/core/config/AppConfig";
import {  GeneralConfigs } from "@/store/model/Config";

export default defineComponent({
  name: "edit_experience",
  props: ["id"],
  setup() {
    const store = useStore();
    const key = "contact_configs";
    const loading = ref<boolean>(false);
    let objectData = ref<GeneralConfigs>({
      header_script: "",
      footer_script: "",
      hotline: "",
      email: "",
      address: "",
      phone: "",
      code_tax: "", // mã số thuế
      date_tax: "", // ngày cấp
      local_provide: "", // nơi cung cấp
      technicians_support: {
        mail: "",
        phone: "",
      },
      popup: {
        image: default_image,
        url: "",
        status:false
      },
      customer_support: {
        mail: "",
        phone: "",
      },
      socials: {
        zalo: "",
        facebook: "",
        youtube: "",
      },
    });
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Cấu hình Website", ["Review"]);
      MenuComponent.reinitialization();

      // await store.dispatch("GET_EXPERIENCE_DETAIL", props.id);
      // await store.dispatch(Actions.GET_ALL_PROVINCE);
      // objectData.value = await store.getters["currentExperience"];
      getConfigs(key).then((data) => {
        if (typeof data === "object")
          objectData.value = { ...objectData.value, ...data };
        //console.log(objectData.value, "xxxxxxxxxx");
      });
      // setConfigs({key:"home_config",value:objectData.value})
    });

    const submit = () => {
      setConfigs({ key, value: objectData.value })
        .then(() => {
          Swal.fire({
            text: "Cập nhật thông dự án thành công",
            icon: "success",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Danh sách",
            cancelButtonText: "Tiếp tục chỉnh sửa",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
              cancelButton: "btn fw-bold btn-light-primary",
            },
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Thử lại!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };
    const media_actions = ref(["popup.image"]);
    const isMultipleMedia = computed(() => store.getters["getIsMultiple"]);

    watch(
      () => store.state.MediaModule.actionCallback,
      (action, second) => {
        //console.log(action);
        if (media_actions.value.includes(action)) {
          let fields = action.split(".");
          //console.log(fields.length);
          switch (fields.length) {
            case 1:
              if (isMultipleMedia.value) {
                objectData.value[action] = store.getters["getSelectMultiMedia"];
              } else {
                objectData.value[action] = store.getters["getSelectMedia"];
              }
              break;
            case 2:
              if (isMultipleMedia.value) {
                objectData.value[fields[0]][fields[1]] =
                  store.getters["getSelectMultiMedia"];
              } else {
                objectData.value[fields[0]][fields[1]] =
                  store.getters["getSelectMedia"];
              }
              break;
            case 3:
              //console.log(objectData.value[fields[0]][fields[1]][fields[2]]);
              if (isMultipleMedia.value) {
                objectData.value[fields[0]][fields[1]][fields[2]] =
                  store.getters["getSelectMultiMedia"];
              } else {
                objectData.value[fields[0]][fields[1]][fields[2]] =
                  store.getters["getSelectMedia"];
              }
              break;
            default:
              break;
          }

          refreshSelectMedia();
        }
      }
    );
    return {
      submit,
      media_actions,
      renderImageUrl,
      showSingleMedia,
      showMultipleMedia,
      loading,
      objectData,
    };
  },
});
